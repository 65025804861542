import {
  computed,
  ref,
  watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import jobStoreModule from '../jobStoreModule';

export default function useJobDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'job';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, jobStoreModule);
  }

  const toastification = toast();
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const jobId = computed(() => props.jobId);

  const fetchDetailData = () => {
    if (jobId.value) {
      isLoading.value = true;
      store.dispatch('job/getJobDetail', jobId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  const reRunJob = () => {
    if (jobId.value) {
      isLoading.value = true;
      store.dispatch('job/reRunJob', jobId.value)
        .then(() => {
          isLoading.value = false;
          toastification.showToastSuccess('Vui lòng đợi chút. Hệ thống đang tiến hành chạy lại job');
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const downloadFile = () => {
    window.location.href = itemLocal.value.fileLocation;
  };

  watch(jobId, () => {

  });

  return {
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchDetailData,
    onOpen,
    isLoading,
    reRunJob,
    downloadFile,
  };
}
