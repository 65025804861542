import {
  ref, watch, onUnmounted,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import jobStoreModule from '../jobStoreModule';

export default function useJobList() {
  const STORE_MODULE_NAME = 'job';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, jobStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  // add / edit
  const blankItem = {
    id: 0,
    name: '',
    phone: '',
    contactName: '',
    address: '',
    url: '',
    note: '',
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
    },
    {
      label: 'File',
      field: 'fileLocation',
    },
    {
      label: 'Loại import',
      field: 'typeObject.title',
    },
    {
      label: 'Thời gian',
      field: 'time',
    },
    {
      label: 'Trạng thái',
      field: 'status',
    },

  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  const fetchJobs = () => {
    store
      .dispatch('job/fetchJobs', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadFile = dataItem => {
    window.location.href = dataItem.fileLocation;
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchJobs();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  const onJobUpdated = job => {
    const temp = rows.value;
    for (let index = 0; index < temp.length; index += 1) {
      if (temp[index].id === job.id) {
        temp[index] = job;
      }
    }
    rows.value = temp;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  //   API Call

  const refetchJobs = () => {
    fetchJobs();
  };

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    refetchJobs,
    fetchJobs,
    downloadFile,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
    onJobUpdated,
  };
}
